import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';

import { SmartLink } from '../SmartLink';

import style from './ReportCard.module.scss';

const ReportCard = ({
    title,
    sector,
    image,
    // mobileImage,
    publishingDate,
    cta,
}) => {
    // const isMobile = useMediaQuery('only screen and (max-width: 599px)');
    return (
        <div className={style.el}>
            <div className={[style.content, !image ? style.noImage : null].join(' ')}>
                {image && (
                    <div className={style.wrapper}>
                        <div className={style.image}>
                            <div className={style.imageWrap}>
                                {/* <img src={isMobile ? mobileImage?.url ? mobileImage.url : image.url : image.url} alt={image.alt} /> */}
                                <img src={image.url} alt={image.alt} />
                            </div>
                        </div>
                    </div>
                )}
                <div className={[style.wrapper, style.right].join(' ')}>
                    <div className={style.header}>
                        <div className={style.type}>{sector}</div>
                        <div className={style.date}>{publishingDate}</div>
                    </div>
                    <div className={style.title}>
                        <h4 className={style.description}>{title}</h4>
                    </div>
                    <div className={style.button}>
                        <SmartLink href={cta.link?.url} hasArrow className='arrow-link'>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportCard.propTypes = {
    title: PropTypes.string,
    sector: PropTypes.string,
    image: PropTypes.object,
    // mobileImage: PropTypes.object,
    publishingDate: PropTypes.string,
    cta: PropTypes.object,
};

export default ReportCard;
