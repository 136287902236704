import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useWindowSize } from '@react-hook/window-size/throttled';

import { SmartLink } from '../SmartLink';
import NewsCard from './NewsCard';
import ReportCard from '../Cards/reportCard';
import CaseStudyCard from '../Cards/caseStudyCard';

import style from './RelatedNews.module.scss';

const RelatedNews = props => {
    const { title, items, cta, cardType } = props;
    if (items.length <= 0) {
        return <></>;
    }
    const sliderEl = useRef(null);
    const wrapRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const arrowsRef = useRef(null);
    const sortDate = (a, b) => {
        return new Date(b.publishingDate) - new Date(a.publishingDate);
    };

    const sliderParams = {
        slidesToShow: 1,
        // arrows: false,
        prevArrow: (
            <button className={style.prevBtn} ref={prevRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        nextArrow: (
            <button className={style.nextBtn} ref={nextRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick',
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        onInit: () => {
            // const fragment = document.createDocumentFragment();
            // fragment.appendChild(prevRef.current);
            // fragment.appendChild(nextRef.current);
            // if (arrowsRef.current.children.length > 0) {
            //     arrowsRef.current.innerHTML = '';
            // }
            // arrowsRef.current.appendChild(fragment);
        },
    };

    const wSize = useWindowSize();

    useEffect(() => {
        const arrH = prevRef.current?.offsetHeight;
        arrowsRef.current.style.height = `${arrH}px`;
        const arrowsBound = arrowsRef.current.getBoundingClientRect();
        const wrapBound = wrapRef.current.firstElementChild.getBoundingClientRect();
        const diffX = arrowsBound.right - wrapBound.right;
        const diffY = arrowsBound.top - wrapBound.top;
        if (prevRef.current && nextRef.current) {
            prevRef.current.style.top = `${diffY}px`;
            prevRef.current.style.right = `${-diffX + 40}px`;
            nextRef.current.style.top = `${diffY}px`;
            nextRef.current.style.right = `${diffX * -1}px`;
        }
        // console.log(diffX, diffY);
    }, [wSize]);

    const renderCard = data => {
        switch (cardType) {
            case 'report':
                return (
                    <div className={style.reportCard}>
                        <ReportCard {...data} />
                    </div>
                );
            case 'caseStudy':
                return (
                    <div className={style.reportCard}>
                        <CaseStudyCard {...data} />
                    </div>
                );
            default:
                return <NewsCard {...data} />;
        }
    };

    return (
        <div className={[style.el, 'related-news'].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.slickArrows} ref={arrowsRef} />
                <div className={style.title}>
                    <h2>{title}</h2>
                </div>
                <div className={style.wrapper} ref={wrapRef}>
                    <Slider {...sliderParams} ref={sliderEl}>
                        {items.length &&
                            items.sort(sortDate).map((item, i) => <Fragment key={i}>{renderCard(item)}</Fragment>)}
                    </Slider>
                </div>
            </div>
            <div className={style.cta}>
                <div className={style.ctaLink}>
                    <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                        {cta.text}
                    </SmartLink>
                </div>
            </div>
        </div>
    );
};

RelatedNews.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
    cardType: PropTypes.string,
};

export default RelatedNews;
